import React, { useEffect, useState } from 'react';
import './ModelForm.css';
import axios from 'axios';
import API_BASE_URL, { addModel, getCategories, addSubCategory } from '../../Services/Api';
import { useParams } from 'react-router-dom';

export default function ModelForm() {
    const {categoryId, subCategoryId, modelId} = useParams(); // 🆕 Récupère les paramètres de l'URL
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [subCategories, setSubCategories] = useState([]);
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [models, setModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);
    const [modelName, setModelName] = useState('');
    const [image, setImage] = useState(null);
    const [technicalSpecificationImage, setTechnicalSpecificationImage] = useState(null);
    const [isUpdate, setIsUpdate] = useState(false);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const data = await getCategories();
                setCategories(data);

                // 📌 Si les paramètres existent dans l'URL, on sélectionne automatiquement la catégorie et sous-catégorie
                if (categoryId) {
                    const category = data.find(cat => `${cat.id}` === categoryId);
                    if (category) {
                        setSelectedCategory(category.name);
                        setSelectedCategoryId(category.id);
                        setSubCategories(category.subCategories);

                        if (subCategoryId) {
                            
                            const subCategory = category.subCategories.find(sub => sub.id.toString() === subCategoryId);
                            if (subCategory) {
                                setSelectedSubCategory(subCategory.id);
                                setModels(subCategory.models);

                                // 📌 Si `modelId` est présent, on sélectionne le modèle automatiquement
                                if (modelId) {
                                    const model = subCategory.models.find(m => m.id.toString() === modelId);
                                    if (model) {
                                        setSelectedModel(model);
                                        setModelName(model.name);
                                        setImage(model.image); // Conserve l'image existante
                                        setTechnicalSpecificationImage(model.technicalSpecificationImage);
                                        setIsUpdate(true); // Active le mode mise à jour
                                    }
                                }
                            }
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, [categoryId, subCategoryId, modelId]); // 🔄 Exécute seulement quand l'URL change

    const handleCategoryChange = (e) => {
        const categoryName = e.target.value;
        setSelectedCategory(categoryName);
        const category = categories.find((cat) => cat.name === categoryName);
        setSelectedCategoryId(category ? category.id : '');
        setSubCategories(category ? category.subCategories : []);
        setSelectedSubCategory('');
        setModels([]);
    };

    const handleSubCategoryChange = (e) => {
        const subCategoryId = e.target.value;
        setSelectedSubCategory(subCategoryId);
        const subCategory = subCategories.find((sub) => sub.id.toString() === subCategoryId);
        setModels(subCategory ? subCategory.models : []);
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]); // Stocke le fichier pour upload si modifié
    };

    const handleTecnicalSpecImageChange = (e) => {
        setTechnicalSpecificationImage(e.target.files[0]); // Stocke le fichier pour upload si modifié
    };

    const handleModelSelect = (e) => {
        const modelId = e.target.value;
        const selected = models.find(model => model.id.toString() === modelId);
        setSelectedModel(selected);
        setModelName(selected?.name || '');
        setImage(selected?.image || null); // Garde l'ancienne image si elle existe
        setIsUpdate(true); // Active le mode mise à jour
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let imageUrl = selectedModel?.image || ''; // Garde l'image actuelle si non modifiée
        let tecImageUrl = selectedModel?.technicalSpecificationImage || '';
        if (image && typeof image !== 'string') {
            const formDataImage = new FormData();
            formDataImage.append('file', image);

            try {
                const imageResponse = await axios.post(`${API_BASE_URL}/upload/image`, formDataImage, {
                    headers: { "Content-Type": "multipart/form-data" }
                });
                imageUrl = imageResponse.data;
            } catch (error) {
                console.error('Error uploading image:', error);
                alert('Erreur lors de l\'upload de l\'image.');
                return;
            }
        }

        if (technicalSpecificationImage && typeof technicalSpecificationImage !== 'string') {
            const formDataTecImage = new FormData();
            formDataTecImage.append('file', technicalSpecificationImage);

            try {
                const tecImageResponse = await axios.post(`${API_BASE_URL}/upload/image`, formDataTecImage, {
                    headers: { "Content-Type": "multipart/form-data" }
                });
                tecImageUrl = tecImageResponse.data;
            } catch (error) {
                console.error('Error uploading image:', error);
                alert('Erreur lors de l\'upload de l\'image.');
                return;
            }
        }

        let id = selectedModel?.id || Math.floor(Math.random() * 9000) + 1000;

        const modelRequest = {
            categoryName: "Category-" + selectedCategoryId,
            subCategoryId: selectedSubCategory,
            model: {
                id: id,
                name: modelName,
                subCategoryId: selectedSubCategory,
                image: imageUrl,
                technicalSpecificationImage : tecImageUrl
            },
            isUpdate: isUpdate
        };

        try {
            await addModel(modelRequest);
            alert(isUpdate ? 'Modèle mis à jour avec succès!' : 'Modèle ajouté avec succès!');
            
            setSelectedCategory('');
            setSubCategories([]);
            setSelectedSubCategory('');
            setModels([]);
            setSelectedModel(null);
            setModelName('');
            setImage(null);
            setTechnicalSpecificationImage(null);
        } catch (error) {
            console.error('Error adding model:', error);
            alert('Une erreur est survenue lors de l\'ajout du modèle.');
        }
    };


    return (
        <div className="model-form">
            <h2>{isUpdate ? 'Mettre à jour un Model' : 'Ajouter un Model'}</h2>
            <form onSubmit={handleSubmit}>
                <table>
                    <tbody>
                        <tr>
                            <td colSpan="2">
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isUpdate}
                                        onChange={() => setIsUpdate(!isUpdate)}
                                    />
                                    Mettre à jour un modèle
                                </label>
                            </td>
                        </tr>
                        
                        <tr>
                            <td colSpan="2">
                                <div className="input-container">
                                    <select
                                        id="categorySelect"
                                        required
                                        value={selectedCategory}
                                        onChange={handleCategoryChange}
                                    >
                                        <option value="">Choisir une Catégorie</option>
                                        {categories.map((category) => (
                                            <option key={category.id} value={category.name}>{category.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </td>
                        </tr>
                        
                        <tr>
                            <td>
                                <div className="input-container">
                                    <select
                                        id="subCategorySelect"
                                        required
                                        value={selectedSubCategory}
                                        onChange={handleSubCategoryChange}
                                        disabled={!selectedCategory}
                                    >
                                        <option value="">Choisir une Sous-Catégorie</option>
                                        {subCategories.map((subCategory) => (
                                            <option key={subCategory.id} value={subCategory.id}>{subCategory.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div className="input-container">
                                <input type="text" name="modelName" required value={modelName} onChange={(e) => setModelName(e.target.value)} />
                                    <label htmlFor="modelName">Nom du Model</label>
                                </div>
                            </td>
                        </tr>
                        {isUpdate && (
                            <tr>
                                <td colSpan="2">
                                    <div className="input-container">
                                        <select
                                            required
                                            value={selectedModel?.id || ''}
                                            onChange={handleModelSelect}
                                            disabled={!selectedCategory || !selectedSubCategory}
                                        >
                                            <option value="">Sélectionner un modèle</option>
                                            {models.map((model) => (
                                                <option key={model.id} value={model.id}>{model.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td>
                                <div className="input-container">
                                    <input
                                        type="file"
                                        name="image"
                                        accept="image/*, application/pdf"
                                        onChange={handleImageChange}
                                    />
                                    <label>Image du Model</label>
                                {image && typeof image === 'string' && (
                                    <img src={`${API_BASE_URL}${image}`} alt="Modèle" className="image-preview" />
                                )}
                                </div>
                            </td>
                            <td>
                                <div className="input-container">
                                    <input
                                        type="file"
                                        name="technicalSpecification"
                                        accept="image/*, application/pdf"
                                        onChange={handleTecnicalSpecImageChange}
                                    />
                                    {technicalSpecificationImage && typeof technicalSpecificationImage === 'string' && (
                                    <img src={`${API_BASE_URL}${technicalSpecificationImage}`} alt="Modèle" className="image-preview" />
                                )}
                                    <label>Fiche Technique</label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                            {localStorage.getItem("isAdmin") == "true" ?(
                                <button type="submit" className="submit-button" disabled={!selectedCategory || !selectedSubCategory}>{isUpdate ? 'Mettre à jour' : 'Ajouter'} le Model</button>

                                ):(
                                    <button type="submit" className="submit-button" disabled={!selectedCategory || !selectedSubCategory}> soumettre a validation</button>

                                )}

                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
}
