import { Link, Navigate, useNavigate } from 'react-router-dom';
import './Decor.css';
import { useEffect } from 'react';
import { deleteProduct ,updateProduct } from '../../../../Services/Api';
import { getCategories} from '../../../../Services/Api';
import API_BASE_URL from '../../../../Services/Api';

export default function Decor({ product, categoryName, subCategoryName, modelId, modelName }) {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when component mounts
    }, []);
    const navigate = useNavigate();
    // Handle delete action
    const handleDelete = async () => {
        if (window.confirm(`Etes-vous sûr de vouloir supprimer le produit ${product.name}?`)) {
            const fetchedCategories = await getCategories();
            const selectedCategory = fetchedCategories.find(cat => cat.name === categoryName);
            await deleteProduct("Category-"+selectedCategory.id,product.id);
            //alert("la category : " + selectedCategory.id + " id du produit : " + product.id);
            alert(`Le Produit ${product.name} a été supprimé avec succès.`);
            window.location.reload(); // Refresh page
        }
    };
    
    // Handle update action
    const handleUpdate = async () => {
        const fetchedCategories = await getCategories();
        const selectedCategory = fetchedCategories.find(cat => cat.name === categoryName);
        navigate(`/updateProduct/${selectedCategory.name}/${subCategoryName}/${modelId}/${product.id}`);
    };
    
    return (
        <div className="decor" >
            <div>
                <img src="../../../images/update.png" onClick={handleUpdate} alt="Update" />
                <img src="../../../images/delete.jpg" onClick={handleDelete} alt="Delete" />
            </div>
            <div>
                <img src={`${API_BASE_URL + product.image}`} alt={product.name} />
                <div>
                    <header>{product.name}</header>
                    <span>{product.code}</span>
                </div>
                <img src={`${API_BASE_URL + product.ambianceImage}`} alt={`${product.name} ambiance`} />
            </div>

        </div>
    );
}
