import { useState, useEffect } from "react";
import { getCategories } from "../Services/Api"; // Import your API call

export default function useCategories() {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const data = await getCategories();
                setCategories(data);
            } catch (err) {
                console.error("Error fetching categories:", err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchCategories();
    }, []); // Run only on mount

    return { categories, loading, error };
}
