import { useState } from "react";
import { Link } from 'react-router-dom';
import "./BackOfficeHeader.css"
const path = `../../../images/logos/`;

export default function BackOfficeHeader() {
    const [isNavbarVisible, setIsNavbarVisible] = useState(false);

    const toggleNavbar = () => {
        setIsNavbarVisible(!isNavbarVisible);
    };

    return (
        <>
            <div id="header" className="back-office-header">
                <Link to="/backOfficeHome"><img id="logo" src={`${path}logo-fn.png`} /></Link>
                <div>
                    <Link className="link" to="/addUser">Nouveau Utilisateur</Link>
                    <Link className="link" to="/addCategory">nouvelle Category</Link>
                    <Link className="link" to="/addSubCategory">nouvelle Sous-Category</Link>
                    <Link className="link" to="/addModel">Nouveau Model</Link>
                    <Link className="link" to="/addProduct">Nouveau Produit</Link>
                </div>
                <img id="menu" className={`menu-icon ${isNavbarVisible ? "rotate" : ""}`} src="../../../icons/menu-green.png" alt="menu" onClick={toggleNavbar} />
            </div>
            <div className={`hidden-navbar ${isNavbarVisible ? "visible" : ""}`} onClick={toggleNavbar}>
                <img id="logo" src={`${path}logo-fn.png`} />
                <Link className="link" to="/addUser">Ajouter un Utilisateur</Link>
                    <Link className="link" to="/addCategory">Ajouter une Category</Link>
                    <Link className="link" to="/addSubCategory">Ajouter une Sous-Category</Link>
                    <Link className="link" to="/addModel">Ajouter un Model</Link>
                    <Link className="link" to="/addProduct">Ajouter un Produit</Link>
            </div>
            <div className={`shadow ${isNavbarVisible ? "shadow-visible" : ""}`} onClick={toggleNavbar}></div>
        </>

    );
}

window.addEventListener("scroll", function () {
    const links = this.document.getElementsByClassName('link');
    if (window.scrollY > 0) {
        this.document.getElementById('header').style.backgroundColor = "rgba(0, 0, 0, 0.7)";
    } else {
        this.document.getElementById('header').style.backgroundColor = '';
    }
});

