import './BackOfficeDecors.css';
import Title from '../../Title/Title';
import useCategories from '../../Services/DynamicCategories'; // Use dynamic categories
import { useParams } from 'react-router-dom';
import Decor from '../../Products/Product/Decors/Decor/Decor';
import { useEffect } from 'react';
import ProductTitle from '../../ProductTitle/ProductTitle';

export default function BackOfficeDecors() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when component mounts
    }, []);

    // Retrieve parameters from URL
    const { id: routeId, categoryName, subCategoryName } = useParams();
    
    // Fetch categories dynamically
    const { categories, loading, error } = useCategories(); 

    // Show loading or error state
    if (loading) return <p>Loading models...</p>;
    if (error) return <p>Error loading models.</p>;

    // Find the corresponding category
    const category = categories.find(cat => cat.name === categoryName);
    if (!category) {
        console.error(`No category found with name: ${categoryName}`);
        return <h2>Aucune catégorie trouvée.</h2>;
    }

    // Find subcategory
    const subCategory = category.subCategories.find(sub => sub.name === subCategoryName);
    if (!subCategory) {
        console.error(`No subcategory found for name: ${subCategoryName}`);
        return <h2>Aucune sous-catégorie trouvée.</h2>;
    }

    // Find model by ID
    const model = subCategory.models.find(m => m.id.toString() === routeId);
    if (!model) {
        console.error(`No model found with ID: ${routeId}`);
        return <h2>Aucun modèle trouvé.</h2>;
    }

    return (
        <div className="decors">
            <ProductTitle 
                categoryName={categoryName} 
                subCategoryName={subCategoryName}
                modelName={model.name}
                path={'../../../'} 
            />

            <div>
                {model.products.length > 0 ? (
                    model.products.map((product) => (
                        <Decor
                            key={product.id}
                            product={product}
                            categoryName={categoryName}
                            subCategoryName={subCategoryName}
                            modelName={model.name}
                            modelId={routeId}
                        />
                    ))
                ) : (
                    <div className="no-decors">
                        <h2>Aucun décor trouvé.</h2>
                    </div>
                )}
            </div>
        </div>
    );
}
