import Category from '../models/Category';
import SubCategory from '../models/SubCategory';
import Product from '../models/Product';
import Usage from '../models/Usage';
import Model from '../models/Model';

//usages
const office = "bureau";
const education = "Education";
const house ="Maison"
const health = "Santé";
const sports = "Sport";
const commerce = "Commerce";
const storage = "Industrie & Stockage"
//Products


//Prodcuts of concept Neo
const centro = new Product(1, 'centro',1195,154,5,10,'Chêne huilé', '../images/models/concept-neo/centro/centro.png', '../images/models/concept-neo/centro/ambiance-centro.png','PRK601','Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]);
const moderna = new Product(2, 'moderna',1195,154,5,10,'Chêne huilé', '../images/models/concept-neo/moderna/moderna.png','../images/models/concept-neo/moderna/ambiance-moderna.png','PRK604', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]);
const scala = new Product(3, 'scala',1195,154,5,10,'Chêne huilé', '../images/models/concept-neo/scala/scala.png','../images/models/concept-neo/scala/ambiance-scala.png','PRK603', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]);
const portello = new Product(4, 'portello',1195,154,5,10,'Chêne huilé', '../images/models/concept-neo/portello/portello.webp','../images/models/concept-neo/portello/ambiance-portello.webp','PRK608', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]);
const lima = new Product(5, 'lima',1195,154,5,10,'Chêne huilé', '../images/models/concept-neo/lima/lima.png','../images/models/concept-neo/lima/ambiance-lima.png','PRK606', 'Always aesthetic, always trendy! Catch fashion and innovation for many years with its impeccable decor.', [office, house]);

//Parquet Stratifié
const conceptNeo = new Model(1, "Concept Neo", "./images/models/concept-neo/concept-neo.png",[centro] )
const effect = new Model(2, "Effect", "./images/models/effect/effect.webp",[centro] )
const natureLine = new Model(3, "Nature Line", "./images/models/nature-line/nature-line.webp",[centro] )
const pruuva = new Model(4, "Pruva", "./images/models/pruuva/pruva.webp",[centro] )
const bella = new Model(5, "Bella", "./images/models/bella/bella.png",[centro] )

//parquet semi massif
const chene = new Model(75, "chene", "./images/models/parquet/ambiance-parquet-chene-contrecolle.jpg",[] )
const umbra = new Model(76, "Umbra (Noyé)", "./images/models/parquet/ambiance-frene-umbra.webp",[] )

//massif
const massifParquet = new Model(77, "Massif chene", "./images/models/parquet/massif.jpg",[] )
const massifParquet2 = new Model(78, "Massif chene chevron", "./images/models/parquet/massif.webp",[] )

//waterproof
const waterfProof = new Model(79, 'WaterProof', './images/models/parquet/ambiance-522.jpg',[]);

//parquet
const stratifié = new SubCategory(1, 'stratifié', [conceptNeo, effect, natureLine, pruuva, bella]);
const semiMassif = new SubCategory(2, 'semi massif', [chene,umbra]);
const massif = new SubCategory(3, 'massif', [massifParquet,massifParquet2]);

//Gerflex residentiel
const atlantic = new Model(6,"Atlantic", "./images/models/atlantic/atlantic.webp", []);
const turbo = new Model(7,"Turbo", "./images/models/turbo/turbo.webp", []);
const enfant = new Model(8,"Enfant", "./images/products/gerflex.jpg", []);

//gerflex - moyen trafic
const blackTex = new Model(27,'BlackTex', './images/models/blacktex/safira.webp',[]);
const texStyle = new Model(28, "Tex-Style","./images/models/tex-style/ambiance-pureOak.webp", [])

//gerflex - grand traffix
const xtreme = new Model(29,'Xtreme', './images/models/xtreme/ambiance-mira990.webp',[]);
const homogeneGrabo = new Model(30,'Homogène grabo', './images/models/homogene/ambiance-almond.jpg',[]);
const homogeneGerflor = new Model(31,'gerflor', './images/models/homogene/homogene-gerflor.jpg',[]);

//gerflex
const residentiel = new SubCategory(4, 'residentiel', [atlantic,turbo,enfant]);
const moyenTrafic = new SubCategory(5, 'moyen trafic', [blackTex, texStyle]);
const grandTrafic = new SubCategory(6, 'grand trafic', [xtreme, homogeneGrabo,homogeneGerflor]);
const lame = new SubCategory(7, 'waterproof', [waterfProof]);

//Gazon artificiel - Decoratif
const decoratif7 = new Model(9, "7 mm/ 10 mm",'../images/models/decoratif-7mm/green.jpg',[]);

//Gazon artificiel - Jardin
const jardin18 = new Model(11, "18 mm", './images/models/sport/iris.jpg', []);
const jardin20 = new Model(12, "20 mm",'./images/models/jardin/jardin-20.jpg',[]);
const jardin28 = new Model(13, "28 mm",'./images/models/talia/talia-2.jpg',[]);
const jardin30 = new Model(14, "30 mm",'./images/models/castor/castor-2.jpg',[]);

//Gazon artificiel - Sport
const sport40 = new Model(16, " 40 mm", './images/models/sport/sport.jpg',[]);
const sport50 = new Model(17, " 50 mm", './images/models/sport/sport.jpg',[]);
const sport55 = new Model(18, " 55 mm", './images/models/sport/sport.jpg',[]);
const sport60 = new Model(19, "60 mm", './images/models/sport/ambiance-sport.jpg',[]);

//Gazon artificiel - Grillage Gazonné
const grillage1 = new Model(32,'1 m', './images/models/grillage/grillage-1G.jpg', [])
const grillage15 = new Model(33,'1,5 m', './images/models/grillage/grillage-15G.jpg', [])
const grillage2 = new Model(34,'2 m', './images/models/grillage/grillage-2G.jpg', [])

const defaultParquet = new Model(991, "PARQUET", './images/products/photo-7.jpg')
const defaultGrass = new Model(992, "GAZON ARTIFICIEL", './images/products/popular-4.jpg')
const defaultPlaco = new Model(993, "PLACO PLATRE", './images/products/placo.jpg')
const defaultGerflex = new Model(994, "GERFLEX", './images/products/gerflex.jpg')
const defaultPaillasson = new Model(995, "paillasson", './images/products/paillasson.jpg')
const defaultBois = new Model(996, "Bois Extérieur", './images/products/home-1.png')
const defaultBardage = new Model(997, "Bardage", './images/products/bardage-2.png')
const defaultStore = new Model(998, "store", './images/products/store.webp')


//gazon artificiel
const decoratif = new SubCategory(8, 'decoratif', [decoratif7]);
const jardin = new SubCategory(9, 'jardin', [jardin18, jardin20, jardin28, jardin30]);
const sport = new SubCategory(10, 'sport', [sport40, sport50]);
const grillage = new SubCategory(11, 'grillage gazonné', [grillage1, grillage15, grillage2]);

//tapis paillasson - spagheti
//const spagheti100 = new Model(20, "1 m", './images/models/spagheti/spagheti-red.jpg',[]);
const spagheti120 = new Model(21, "1ml - 1,20 ml", './images/models/spagheti/spagheti-blue.jpg',[]);

//tapis paillasson - zemat
//const zemat90 = new Model(22, "0,90 m", './images/models/zemat/zemat-blue.jpeg',[]);
const zemat120 = new Model(23, "0,90 ml - 1,20 ml", './images/models/zemat/zemat-green.jpeg',[]);

//tapis paillasson - honey
//const honey90 = new Model(24, "0,90 m", './images/models/honey/honey-blue.jpg',[]);
const honey120 = new Model(25, "0,90 ml - 1,20 ml", './images/models/honey/honey-green.jpg',[]);

//tapis paillasson - aspro
const aspro120 = new Model(26, "1,20 ml", './images/models/aspro/aspro-black.jpg',[]);

//moquette
const medusa = new Model(40,'Medusa','./images/models/moquette/ambiance-medusa-11.jpg',[]);
const mambo = new Model(41, 'Mambo', './images/models/moquette/ambiance-mambo-49.jpg', []);
const basmat = new Model(42, 'Basmat', './images/models/moquette/ambiance-basmat.png',[]);
const star = new Model(43, 'Autres', './images/models/moquette/ambiance-diamante.png', [])

//tapis paillasson
const spagheti = new SubCategory(12, 'spaghetti', [spagheti120]);
const zemat = new SubCategory(13, 'ZMat', [zemat120]);
const honney = new SubCategory(14, 'honey', [honey120]);
const aspro = new SubCategory(15, 'aspro', [aspro120]);
const moquette = new SubCategory(25, 'Moquette', [basmat,medusa, mambo, star]);

//Bambou
const bambou1 = new Model(35, 'sol', './images/models/bambou/ambiance-bardage-sol.jpg', [] );
const bambou2 = new Model(36, 'mur','./images/models/bambou/ambiance-bardage-mur.jpg', []);

//teak
const teak1 = new Model(37, 'Teak', './images/models/teak/ambiance-teak.png', []);

//ipe
const ipe1 = new Model(38, 'IPE', './images/models/ipe/ambiance-ipe.png', []);

//composite
const composite1 = new Model(39, 'Composite', './images/models/composite/ambiance-composite.webp', []);



//bois exterieur
const bambou = new SubCategory(16, 'bambou', [bambou1, bambou2]);
const teak = new SubCategory(17, 'teak - Garapa', [teak1]);
const ipe = new SubCategory(18, 'ipe', [ipe1]);
const composite = new SubCategory(19, 'composite', [composite1]);

//Bardage habillage - Papier Peint
/*const grandeco = new Model(42, 'Grandeco','./images/models/papier-peint/ambiance-arcadia.jpg',[]);
const komar = new Model(43, 'Komar','./images/models/papier-peint/tapestry.jpg',[]);
const lutece = new Model(44, 'Lutèce','./images/models/papier-peint/affinity.webp',[]);
const designId = new Model(45, 'Design Id','./images/models/papier-peint/tapestry.jpg',[]);
const marburg = new Model(46, 'Marburg','./images/models/papier-peint/marburg.jpg',[]);
const novamur = new Model(47, 'Novamur','./images/models/papier-peint/ivy.jpg',[]);
const enfantPapierPeint = new Model(48, 'Enfant','./images/models/papier-peint/disneyjpg.jpg',[]);
const eijffinger = new Model(49, 'Eijffinger','./images/models/papier-peint/jasmin.webp',[]);*/

const papierpPeint = new Model(424, 'Paiper Peint','./images/models/papier-peint/ambiance-arcadia.jpg',[]);
const enfantPapierPeint = new Model(43, 'Enfant','./images/models/papier-peint/disneyjpg.jpg',[]);
const personalised = new Model(44, 'Poster & Personnalisé','./images/models/papier-peint/ivy.jpg',[]);

//Liége
const liegeModel = new Model(50, 'Liége', './images/models/bardage/ambiance-liege.jpg')

//spc
const spcModel = new Model(51,'SPC', './images/models/bardage/ambiance-spc.jpg');

//wpc - mdf
const wpcModel = new Model(52, 'Bardage', './images/models/bardage/ambiance-jaune.png')

//bardage habillage
const spc = new SubCategory(21, 'spc', [spcModel]);
const wpc = new SubCategory(22, 'Bardage', [wpcModel]);
const liége = new SubCategory(23, 'liége', [liegeModel]);
const papierPeint = new SubCategory(24, 'papier peint', [papierpPeint]);

//Stores - models
const vertical = new Model(69, 'Verticale', './images/models/stores/verticale.webp')
const enRouleur = new Model(70, 'Enrouleur', './images/models/stores/en-rouleur.jpg')
const dayNight = new Model(71, 'Jour et Nuit', './images/models/stores/day-night.jpg')
const balckOut = new Model(72, 'Black Out', './images/models/stores/black-out.jpg')
const wave = new Model(73, 'Wave', './images/models/stores/wave.webp')
const japanaise = new Model(74, 'Japonaise', './images/models/stores/japonaise.webp')

//Stores
const stores = new SubCategory(25, 'Stores', [vertical, enRouleur, dayNight, balckOut, wave, japanaise]);

//placo platre - faux plafond
const platre = new Model(53, 'En Platre', './images/models/plafond/turb.jpg');
const bois = new Model(54, 'En Bois', './images/models/plafond/bois.jpg');
const metalique = new Model(55, 'Metalique ', './images/models/plafond/metalique-gris.jpg');
const fibre = new Model(56, 'En Fibre Minérale', './images/models/plafond/fibre.jpg');
const laineRoche = new Model(57,'En Lain de Roche', './images/models/plafond/laine-roche.jpg');

//placo platre - BA-13
const std = new Model(58, 'STD', './images/models/plafond/std.jpg');
const hydrofuge = new Model(59, 'hydrofuge', './images/models/plafond/ambiance-hydro.jfif');
const coupeFeu = new Model(60, 'Coupe Feu', './images/models/plafond/ambiance-coup.png');
const phonique = new Model(61, 'Phonique', './images/models/plafond/ambiance-phonique.jfif');
const acoustic = new Model(62, 'Acoustique','./images/models/plafond/acous.jpg');
const plafond = new Model(63, 'Plafond');
const cloison = new Model(64, 'Cloison');
const habillage = new Model(65, 'Habillage');

//placo platre - isolation
//laine de roche
const laineVerre = new Model(66, 'Laine de Verre', './images/models/plafond/ambiance-laine-verre.jpg');
const polystyrène = new Model(67, 'Polystyrène Extrudé', './images/models/plafond/polysterine.jpg')
const liege = new Model(68, 'Liège', './images/models/plafond/liege.jpg')

//placo platre
const fauxPlafond = new SubCategory(25, 'Faux Plafond', [platre,bois,metalique,fibre]);
const ba13 = new SubCategory(26, 'BA-13', [std,hydrofuge,coupeFeu,phonique,acoustic]);
const isolation = new SubCategory(27, 'Isolation', [laineRoche,laineVerre,polystyrène,liege]);
const ossature = new SubCategory(28, 'Ossature', []);

const parquet = new Category(1, 'parquet', [massif, semiMassif, stratifié, lame ]);
const gerflex = new Category(2, 'gerflex (vinyle)', [residentiel, moyenTrafic, grandTrafic, lame]);
const gazonArtificiel = new Category(3, 'gazon artificiel', [ jardin, decoratif,sport, grillage]);
const tapisPaillasson = new Category(4, 'tapis paillasson', [spagheti, zemat, honney, aspro, moquette]);
const boisExterieur = new Category(5, 'bois extérieur', [bambou, teak, ipe, composite]);
const bardage = new Category(6, 'bardage - habillage', [ wpc, spc,liége,papierPeint]);
const placoPlatre = new Category(7, 'placo platre', [fauxPlafond,ba13, isolation, ossature]);
const store = new Category(8, 'Stores', [stores]);



const categories = [parquet, gerflex, gazonArtificiel, tapisPaillasson, boisExterieur, bardage, placoPlatre,store];

export default categories;

//à faire
/**
 * Valider la partie des produits : (les images, les texts, context d'utilisation, dimension)
 * passer 
 * >>>> parquet madame Farah
 * >>>> gerflex madame Asmae
 * >>>> accessoires madame Meryem
 */