import Model from '../Product/Model';
import ProductTitle from '../../ProductTitle/ProductTitle';
import './ProductsList.css';
import Title from '../../Title/Title';
import { useEffect, useState } from 'react';
import { getCategories } from '../../Services/Api';

export default function ProductsList({
    modelsBySubCategory,
    categoryName,
    subCategoryName,
    isDefaultMode = false,
    onDefaultModelClick,
    isBackOffice = false,
    onModelEdit,
    onModelDelete,
}) {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        async function fetchCategories() {
            try {
                const data = await getCategories();
                console.log("✅ Categories fetched successfully:", data); // Debug log
                setCategories(data);
            } catch (error) {
                console.error('❌ Error fetching categories:', error);
            }
        }
        fetchCategories();
    }, []);

    const category = categories.find(cat => cat.name === categoryName);
    return (
        <div className="products-list">
            <ProductTitle categoryName={categoryName} subCategoryName={subCategoryName} path={'../../../'} />
            <div className='group'>
                {modelsBySubCategory.map((group) => (
                    <div key={group.subCategoryName}>
                        <Title content={group.name} />
                        <div className="models-group">
                            {group.models.map((model) => (
                                <div key={model.id} className="backoffice-model">
                                    {isDefaultMode ? (
                                        // 👉 Quand on est en mode défaut, on redirige au clic
                                        <div className="product" onClick={() => onDefaultModelClick(model.name)}>
                                            <div>
                                                <img src={model.image} alt={model.name} />
                                                <header>{model.name}</header>
                                            </div>
                                        </div>
                                    ) : (
                                        // 👉 Sinon, comportement normal
                                        <Model model={model} category={category} />
                                    )}
                                    {isBackOffice && (
                                        <div className="backoffice-actions">
                                            <img src='./images/update.png' onClick={() => onModelEdit(model.id, group.name)} />
                                            <img src='./images/delete.jpg' onClick={() => onModelDelete(model.id)} />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
