import { Navigate, useNavigate } from 'react-router-dom';
import ProductsList from '../../Products/ProductsList/ProductsList';
import { deleteModel, getCategories } from '../../Services/Api';
import './BackOfficeProducts.css';

export default function BackOfficeProducts({ modelsBySubCategory, categoryName, subCategoryName }) {
    const navigate = useNavigate();

    const handleEdit = async (modelId, subName) => {
        const categories = await getCategories();
        const selectedCategory = categories.find(cat => cat.name === categoryName);
        const selectedSubCategory = selectedCategory.subCategories.find(sub => sub.name === subName);
        navigate(`/updateModel/${selectedCategory.id}/${selectedSubCategory.id}/${modelId}`);
    };

    const handleDelete = async (modelId) => {
        if (window.confirm(`Etes-vous sûr de vouloir supprimer le model ${modelId}?`)) {
            try {
                // Fetch categories to find subCategoryId
                const categories = await getCategories();
                
                // Find the correct category
                const selectedCategory = categories.find(cat => cat.name === categoryName);
                if (!selectedCategory) {
                    console.error(`Category not found: ${categoryName}`);
                    return;
                }
    
                // Find the correct subcategory inside the category
                const selectedSubCategory = selectedCategory.subCategories.find(sub => sub.name === subCategoryName);
                if (!selectedSubCategory) {
                    console.error(`SubCategory not found: ${subCategoryName}`);
                    return;
                }
    
                const subCategoryId = selectedSubCategory.id; // Extract subCategoryId
    
                // Call deleteModel with subCategoryId instead of subCategoryName
                await deleteModel("Category-"+selectedCategory.id, subCategoryId, modelId);
                console.log(`✅ Model ${modelId} deleted successfully in category: ${selectedCategory.id}, subCategoryId: ${subCategoryId}`);
                window.location.reload(); // Refresh page
            } catch (error) {
                console.error("❌ Error fetching categories:", error);
            }
        }
    };
    

    return (
        <div className="back-office-products">
            <ProductsList
                modelsBySubCategory={modelsBySubCategory}
                categoryName={categoryName}
                subCategoryName={subCategoryName}
                isBackOffice={true}
                onModelEdit={handleEdit}
                onModelDelete={handleDelete}
            />
        </div>
    );
}
