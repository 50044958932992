import { Link, useLocation } from 'react-router-dom';
import API_BASE_URL from '../../Services/Api';
import './Model.css';

export default function Model({ model, category }) {
    const location = useLocation();

    if (!model) {
        console.warn("⚠️ Model prop is undefined.");
        return null;
    }

    if (!category) {
        console.warn(`🚨 No category provided for model: ${model.name}`);
        return null;
    }

    const subCategory = category.subCategories?.find(sub =>
        sub.models?.some(m => m.id === model.id)
    );

    if (!subCategory) {
        console.warn(`🚨 No subcategory found for model ID: ${model.id}`);
        return null;
    }

    const foundModel = subCategory.models?.find(m => m.id === model.id);
    if (!foundModel) {
        console.warn(`🚨 No model found with ID: ${model.id}`);
        return null;
    }

    const productId = foundModel.products?.length > 0 ? foundModel.products[0].id : null;
    const isBackOffice = location.pathname.includes('/backOffice');

    let linkPath = isBackOffice
        ? `/backOfficeDecors/${category.name}/${subCategory.name}/${model.id}`
        : `/DecorDetails/${category.name}/${subCategory.name}/${model.name}/${productId}/${model.id}`;
    console.log("✅ Model rendered successfully:", foundModel);

    if (model.name.toLowerCase().includes("peint")) {
        linkPath = `/decors/${category.name}/${subCategory.name}/${model.id}`;
    }

    return (
        <Link key={model.id} to={linkPath}>
            <div className="product">
                <div>
                    <img src={`${API_BASE_URL + model.image}`} alt={model.name} />
                    <header>{model.name}</header>
                </div>
            </div>
        </Link>
    );
}
