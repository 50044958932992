import ClientsList from '../ClientsList/ClientsList';
import Title from '../Title/Title';
import './AboutUs.css';

export default function AboutUs() {
    const clients = [
        './images/clients/client-1.png',
        './images/clients/client-2.png',
        './images/clients/client11.png',
        './images/clients/client12.png',
        './images/clients/client-3.png',
        './images/clients/client-6.png',
        './images/clients/client-8.png',
        './images/clients/client-9.png',
        './images/clients/client-10.png',
        './images/clients/client-12.svg',
        './images/clients/client-4.jpg',
        './images/clients/client-13.png',
    ];
    const partners = [
        './images/partners/partner-1.png',
        './images/partners/partner-2.png',
        './images/partners/partner-3.png',
        './images/partners/partner-4.png',
        './images/partners/partner-5.png',
        './images/partners/partner-6.jpg',
        './images/partners/partner-7.png',
        './images/partners/partner-8.png',
        './images/partners/partner-9.png',
        './images/partners/partner-10.png',
        './images/partners/partner-11.png',
        './images/partners/partner-12.png',
        './images/partners/partner-13.png',
        './images/partners/partner-14.png',
    ];
    return (
        <div className='about-us'>
            <div>
                <div>
                    <img src='./images/avatar.webp' alt='CEO' />
                </div>
                <div>
                    <p>"Chez Decopa, notre mission va bien au-delà de la simple
                        fourniture de produits. Nous nous engageons à transformer
                        vos espaces en véritables œuvres d'art fonctionnelles,
                        en alliant qualité, innovation et esthétique."
                    </p>
                    <span>
                        Tazi Taoufik - CEO et fondateur de DECOPA
                    </span>
                </div>
            </div>
            <Title content={"Historique"} />
            <div>
                <div>
                    <p><span>DECOPA</span>, fondée en <span>1995</span>, a débuté comme une petite entreprise familiale spécialisée dans la <span>distribution de revêtements de sols, murs et plafond</span>distribution de revêtements de sols et murs, avec une ambition simple : offrir des produits de qualité supérieure et un service client irréprochable. Dès ses débuts, <span>DECOPA</span> s'est démarquée par son engagement envers l'innovation et sa capacité à s'adapter aux tendances du marché.Au fil des années, la société a élargi son portefeuille, introduisant des produits phares tels que les parquets stratifiés, les gazons synthétiques, et les revêtements en vinyle, devenant ainsi un acteur incontournable dans le secteur du design intérieur et extérieur. En <span>2008</span>, <span>DECOPA</span> a franchi une étape clé en établissant des partenariats avec des marques internationales reconnues, renforçant sa position sur le marché et enrichissant son offre avec des produits écologiques et durables.
                        Aujourd'hui, Decopa est fière de servir une clientèle diversifiée, allant des particuliers aux professionnels du bâtiment et de la décoration. Avec une équipe de plus de 200 collaborateurs et un réseau de distribution présent dans toute la région, l'entreprise continue d'innover pour répondre aux attentes de ses clients tout en contribuant à un avenir plus durable.</p>
                </div>
                <div>
                    <img src="./images/logos/logo-3.png" />
                </div>
            </div>
            <Title content={"Parmis nos Clients"} />
            <ClientsList data={clients} />
            <Title content={"Partenaires"} />
            <ClientsList data={partners} />
        </div>
    );
}