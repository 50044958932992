import React, { useState, useEffect } from 'react';
import './ProductForm.css';
import API_BASE_URL, { getCategories, addProduct, getProductById } from '../../Services/Api';
import axios from 'axios';
import { useParams } from 'react-router-dom';

export default function ProductForm() {
    const {categoryName, subName, modelId, productId } = useParams(); // 📌 Récupère les paramètres de l'URL
    const [categories, setCategories] = useState([]);
    const [isUpdateMode, setIsUpdateMode] = useState(false); // Toggle pour ajouter / mettre à jour
    const [isLink, setIsLink] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [formState, setFormState] = useState({
        category: '',
        subCategory: '',
        model: '',
        link: '',
        productName: '',
        description: '',
        image: '',
        ambianceImage: '',
        width: '',
        widthUnit: 'mm',
        length: '',
        lengthUnit: 'mm',
        thickness: '',
        thicknessUnit: 'mm',
        surface: '',
        usages: '',
        subCategories: [],
        models: [],
        products: [],
    });
    useEffect(() => {
        async function fetchCategories() {
            try {
                const fetchedCategories = await getCategories();
                setCategories(fetchedCategories);

                const category = fetchedCategories.find(cat => cat.name === categoryName);
                if (category) {
                    setFormState(prev => ({
                        ...prev,
                        category: category.id,
                        subCategories: category.subCategories
                    }));

                    const subCategory = category.subCategories.find(sub => sub.name === subName);
                    if (subCategory) {
                        setFormState(prev => ({
                            ...prev,
                            subCategory: subCategory.id,
                            models: subCategory.models
                        }));

                        const model = subCategory.models.find(m => m.id.toString() === modelId);
                        if (model) {
                            setFormState(prev => ({
                                ...prev,
                                model: model.id,
                                products: model.products
                            }));

                            if (productId) {
                                const product = model.products.find(p => p.id.toString() === productId);
                                if (product) {
                                    setSelectedProduct(product);
                                    setFormState(prev => ({
                                        ...prev,
                                        productName: product.name || '',
                                        description: product.description || '',
                                        image: product.image || '',
                                        ambianceImage: product.ambianceImage || '',
                                        widthUnit: product.width.replace(/[\d]/g,'') || '',
                                        lengthUnit: product.length.replace(/[\d]/g,'') || '',
                                        thicknessUnit: product.thickness.replace(/[\d]/g,'') || '',
                                        width: product.width.replace(/\D/g, '') || '',
                                        length: product.length.replace(/\D/g, '') || '',
                                        thickness: product.thickness.replace(/\D/g, '') || '',
                                        surface: product.surface || '',
                                        usages: product.usages ? product.usages.split(',') : '',
                                    }));
                                }
                            }
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        }
        fetchCategories();
    }, [categoryName, subName, modelId, productId]);
    useEffect(() => {
        async function fetchCategories() {
            try {
                const fetchedCategories = await getCategories();
                setCategories(fetchedCategories);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        }
        fetchCategories();
    }, []);

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        let newValue;
        if (files) {
            newValue = files[0]; // Store the entire file object, not just the name
        } else {
            newValue = value;
        }

        setFormState((prevState) => ({
            ...prevState,
            [name]: newValue,
        }));

        if (name === 'category') {
            const selectedCategory = categories.find((cat) => cat.id.toString() === value);
            setFormState((prevState) => ({
                ...prevState,
                category: selectedCategory ? selectedCategory.id : '', // ✅ Store ID
                subCategories: selectedCategory ? selectedCategory.subCategories : [],
                subCategory: '',
                models: [],
                model: '',
            }));
        } else if (name === 'subCategory') {
            const selectedSubCategory = formState.subCategories.find(
                (sub) => sub.id.toString() === value
            );
            setFormState((prevState) => ({
                ...prevState,
                models: selectedSubCategory ? selectedSubCategory.models : [],
                model: '',
            }));
        } else if (name === 'model') {
            const selectedModel = formState.models.find(model => model.id.toString() === value);
            setFormState(prevState => ({
                ...prevState,
                model: selectedModel ? selectedModel.id : '',
                products: selectedModel ? selectedModel.products : [],
            }));
        }
    };

    const handleProductSelect = (e) => {
        const productId = e.target.value;
        const selectedProd = formState.products.find(prod => prod.id.toString() === productId);
        setSelectedProduct(selectedProd);
    
        if (selectedProd) {
            setFormState({
                ...formState,
                productName: selectedProd.name || '',
                description: selectedProd.description || '',
                image: selectedProd.image || '',
                ambianceImage: selectedProd.ambianceImage || '',
                widthUnit: selectedProd.width.replace(/[\d]/g,'') || '',
                lengthUnit: selectedProd.length.replace(/[\d]/g,'') || '',
                thicknessUnit: selectedProd.thickness.replace(/[\d]/g,'') || '',
                width: selectedProd.width.replace(/\D/g,'') || '',
                length: selectedProd.length.replace(/\D/g,'') || '',
                thickness: selectedProd.thickness.replace(/\D/g,'') || '',
                surface: selectedProd.surface || '',
                technicalSpecification: selectedProd.technicalSpecification || '',
                usages: selectedProd.usages ? selectedProd.usages.split(',') : '',
            });
        }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {

            let imagePath = formState.image;
            let ambianceImagePath = formState.ambianceImage;

            if (formState.image && typeof formState.image !== 'string') {
                const formDataImage = new FormData();
                formDataImage.append("file", formState.image);
                const imageResponse = await axios.post(`${API_BASE_URL}/upload/image`, formDataImage, {
                    headers: { "Content-Type": "multipart/form-data" }
                });
                imagePath = imageResponse.data;
            }
    
            // 2️⃣ Upload de la nouvelle ambiance image uniquement si elle a été changée
            if (formState.ambianceImage && typeof formState.ambianceImage !== 'string') {
                const formDataAmbiance = new FormData();
                formDataAmbiance.append("file", formState.ambianceImage);
                const ambianceResponse = await axios.post(`${API_BASE_URL}/upload/image`, formDataAmbiance, {
                    headers: { "Content-Type": "multipart/form-data" }
                });
                ambianceImagePath = ambianceResponse.data;
            }

            

            // 3️⃣ Create Product Object
            const prodcutRequest = {
                categoryName: "Category-" + formState.category,
                subCategoryId: formState.subCategory,
                modelId: formState.model,
                product: {
                    id: selectedProduct?.id || Math.floor(Math.random() * 9000) + 1000,
                    modelId: formState.model,
                    name: formState.productName,
                    link: formState.link,
                    description: formState.description,
                    image: imagePath, // Use uploaded image path
                    ambianceImage: ambianceImagePath, // Use uploaded ambiance image path
                    width: formState.width + formState.widthUnit,
                    length: formState.length + formState.lengthUnit,
                    thickness: formState.thickness + formState.thicknessUnit,
                    surface: formState.surface,
                    usages: Array.isArray(formState.usages) ? formState.usages.join(",") : formState.usages,
                }
            }

            // 4️⃣ Send Product Data to Backend

            await addProduct(prodcutRequest);
            alert('Produit ajouté avec succès!');

            // 5️⃣ Reset Form
            setFormState({
                category: '',
                subCategory: '',
                model: '',
                link: '',
                productName: '',
                description: '',
                image: '',
                ambianceImage: '',
                width: '',
                widthUnit: 'mm',
                length: '',
                lengthUnit: 'mm',
                thickness: '',
                thicknessUnit: 'mm',
                surface: '',
                usages: '',
                subCategories: [],
                models: [],
                products: [],
            });

        } catch (error) {
            console.error('Error adding product:', error);
            alert('Erreur lors de l\'ajout du produit.');
        }
    };


    return (
        <div className="product-form">
            <h2>{isUpdateMode ? 'Modifier un Produit' : 'Ajouter un Produit'}</h2>

            {/* 🆕 Toggle Add / Update */}
            <label className="switch">
                <input type="checkbox" checked={isUpdateMode} onChange={() => setIsUpdateMode(!isUpdateMode)} />
                <span className="slider round"></span> {isUpdateMode ? "Ajouter" : "Modifier"}
            </label>

            <label className="switch">
                <input type="checkbox" checked={isLink} onChange={() => setIsLink(!isLink)} />
                <span className="slider round">Inserer un lien</span>
            </label>
            <form onSubmit={handleSubmit}>
                <table>
                    <tbody>
                        {/* Category Selection */}
                        <tr>
                            <td colSpan="2">
                                <div className="input-container">
                                    <select
                                        name="category"
                                        value={formState.category || ''}
                                        onChange={handleChange}
                                    >
                                        <option value="">Choisir une Catégorie</option>
                                        {categories.map((cat) => (
                                            <option key={cat.id} value={cat.id}>
                                                {cat.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </td>
                        </tr>
                        {/* Subcategory Selection */}
                        <tr>
                            <td>
                                <div className="input-container">
                                    <select
                                        name="subCategory"
                                        disabled={!formState.subCategories.length}
                                        value={formState.subCategory || ''}
                                        onChange={handleChange}
                                    >
                                        <option value="">
                                            {formState.category
                                                ? 'Choisir une Sous-Catégorie'
                                                : 'Sélectionnez une Sous-Catégorie'}
                                        </option>
                                        {formState.subCategories.map((sub) => (
                                            <option key={sub.id} value={sub.id}>
                                                {sub.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </td>
                            <td>
                                <div className="input-container">
                                    <select
                                        name="model"
                                        required
                                        disabled={!formState.models.length}
                                        value={formState.model || ''}
                                        onChange={handleChange}
                                    >
                                        <option value="">
                                            {formState.subCategory
                                                ? 'Choisir un Modèle'
                                                : 'Sélectionnez un Modèle'}
                                        </option>
                                        {formState.models.map((model) => (
                                            <option key={model.id} value={model.id}>
                                                {model.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </td>
                        </tr>
                        {isUpdateMode && (
                            <tr>
                                <td colSpan="2">
                                    <div className="input-container">
                                        <select name="selectedProduct" onChange={handleProductSelect} disabled={!formState.products.length}>
                                            <option value="">Sélectionner un Produit</option>
                                            {formState.products.map(prod => (
                                                <option key={prod.id} value={prod.id}>{prod.name}</option>
                                            ))}
                                        </select>
                                    </div>

                                </td>
                            </tr>
                        )}

                        {isLink && (
                            <>
                            <tr>
                                <td>
                                    <div className="input-container">
                                        <input type="file" name="image" accept="image/*" onChange={handleChange} />
                                        
                                        <label>Image du Produit</label>
                                        {formState.image && typeof formState.image === 'string' && (
                                            <div className="image-preview">
                                                <img src={`${API_BASE_URL}${formState.image}`} alt="Produit" className="image-preview" />
                                            </div>
                                        )}
                                    </div>
                                </td>
                                    <td>
                                        <div className="input-container">
                                            <input
                                                type="file"
                                                name="ambianceImage"
                                                accept="image/*"
                                                onChange={handleChange}
                                            />
                                            <label>Image du Produit</label>
                                            {formState.ambianceImage && typeof formState.ambianceImage === 'string' && (
                                                <div className="image-preview">
                                                    <img src={`${API_BASE_URL}${formState.ambianceImage}`} alt="Aperçu ambiance" />
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan="2">
                                        <div className="input-container">
                                            <input
                                                type="text"
                                                name="link"
                                                required
                                                value={formState.link}
                                                onChange={handleChange}
                                            />
                                            <label>Lien</label>
                                        </div>

                                    </td>
                                </tr>
                            </>
                        )}
                        {!isLink && (
                            <>
                                <tr>
                                    <td>
                                        <div className="input-container">
                                            <input
                                                type="text"
                                                name="productName"
                                                required
                                                value={formState.productName}
                                                onChange={handleChange}
                                            />
                                            <label>Nom du Produit</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-container">
                                            <input
                                                type="text"
                                                name="description"
                                                
                                                value={formState.description}
                                                onChange={handleChange}
                                            />
                                            <label>Description du Produit</label>
                                            
                                        </div>
                                    </td>
                                </tr>
                                {/* Images */}
                                <tr>
                                    <td>
                                        <div className="input-container">
                                            <input
                                                type="file"
                                                name="image"
                                                accept="image/*"
                                                onChange={handleChange}
                                            />
                                            <label>Image du Produit</label>
                                            {formState.image && typeof formState.image === 'string' && (
                                            <div className="image-preview">
                                                <img src={`${API_BASE_URL}${formState.image}`} alt="Produit" className="image-preview" />
                                            </div>
                                        )}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-container">
                                            <input
                                                type="file"
                                                name="ambianceImage"
                                                accept="image/*"
                                                onChange={handleChange}
                                            />
                                            <label>Image d'Ambiance</label>
                                            {formState.ambianceImage && typeof formState.ambianceImage === 'string' && (
                                                <div className="image-preview">
                                                    <img src={`${API_BASE_URL}${formState.ambianceImage}`} alt="Aperçu ambiance" />
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                                {/* Dimensions */}
                                <tr>
                                    <td>
                                        <div className="input-container">
                                            <input
                                                type="number"
                                                name="width"
                                                required
                                                value={formState.width}
                                                onChange={handleChange}
                                            />
                                            <select className='unit' name="widthUnit" value={formState.widthUnit} onChange={handleChange}>
                                                <option value=" cm">cm</option>
                                                <option value=" mm">mm</option>
                                                <option value=" m">m</option>
                                                <option value=" kg">kg</option>
                                                <option value=" Unité">Unité</option>
                                                <option value=" m²">m²</option>
                                                <option value=" ML">ML</option>
                                                <option value=" RLX">RLX</option>
                                                <option value=" L">L</option>
                                                <option value=" Palet">Palet</option>
                                            </select>
                                            <label>Largeur</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-container">
                                            <input
                                                type="number"
                                                name="length"
                                                required
                                                value={formState.length}
                                                onChange={handleChange}
                                            />
                                            <select className='unit' name="lengthUnit" value={formState.lengthUnit} onChange={handleChange}>
                                                <option value=" cm">cm</option>
                                                <option value=" mm">mm</option>
                                                <option value=" m">m</option>
                                                <option value=" kg">kg</option>
                                                <option value=" Unité">Unité</option>
                                                <option value=" m²">m²</option>
                                                <option value=" ML">ML</option>
                                                <option value=" RLX">RLX</option>
                                                <option value=" L">L</option>
                                                <option value=" Palet">Palet</option>
                                            </select>
                                            <label>Longueur</label>
                                        </div>
                                    </td>
                                </tr>
                                {/* Technical Details */}
                                <tr>
                                    <td>
                                        <div className="input-container">
                                            <input
                                                type="number"
                                                name="thickness"
                                                required
                                                value={formState.thickness}
                                                onChange={handleChange}
                                            />
                                            <select className='unit' name="thicknessUnit" value={formState.thicknessUnit} onChange={handleChange}>
                                                <option value=" cm">cm</option>
                                                <option value=" mm">mm</option>
                                                <option value=" m">m</option>
                                                <option value=" kg">kg</option>
                                                <option value=" Unité">Unité</option>
                                                <option value=" m²">m²</option>
                                                <option value=" ML">ML</option>
                                                <option value=" RLX">RLX</option>
                                                <option value=" L">L</option>
                                                <option value=" Palet">Palet</option>
                                            </select>
                                            <label>Epaisseur</label>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="input-container">
                                            <input
                                                type="text"
                                                name="surface"
                                                value={formState.surface}
                                                onChange={handleChange}
                                            />
                                            <label>Surface du Produit</label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="input-container">
                                            <input
                                                type="text"
                                                name="usages"
                                                value={formState.usages}
                                                onChange={handleChange}
                                            />
                                            <label>Contexts d'Utilisation (séparer par espace)</label>
                                        </div>
                                    </td>
                                    
                                </tr>
                                {/* Submit Button */}

                            </>
                        )}
                        {/* Product Details */}
                        <tr>
                            <td colSpan="2">
                                {localStorage.getItem("isAdmin") == "true" ? (
                                    <button type="submit" className="submit-button">
                                        Ajouter le Produit
                                    </button>
                                ) : (
                                    <button type="submit" className="submit-button">
                                        soumettre a validation
                                    </button>
                                )}

                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
}
