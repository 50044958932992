import { Link, useNavigate } from 'react-router-dom';
import './Login.css';
import { useState } from 'react';
import { authenticateUser } from '../Services/Api';

export default function Login(){

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        const success = await authenticateUser(username, password);
        if (success) {
            navigate("/backOfficeHome");
        } else {
            alert("Échec de l'authentification. Vérifiez votre identifiant et mot de passe.");
        }
    };

    return(
        <div className="login">
            <div>
                <div>
                    <img src="./images/logos/admin.webp" />
                </div>
                <form onSubmit={handleLogin}>
                    <span>Admin Login</span>
                    <input type="text" placeHolder="Login" value={username} onChange={(e) => setUsername(e.target.value)} required/>
                    <input type="password" placeHolder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required/>
                    <button type="submit">Se Connecter</button>
                </form>
            </div>
        </div>
    );
}