import { addCategory, addSubCategory, addModel, addProduct ,getCategories, getModels, getSubCategories} from "../Services/Api";
import categories from "../../data/Categories";
import data from "../../data/Models";

export const fillCategories = async (categories) => {
    try {
        for (const category of categories) {
            console.log(`Adding category: ${category.name}`);
            await addCategory({ name: category.name });
        }
        console.log("Categories added successfully!");
    } catch (error) {
        console.error("Error while inserting categories:", error);
    }
};


export const fillSubCategories = async (categories) => {
    try {
        const existingCategories = await getCategories(); // Fetch all inserted categories

        for (const category of categories) {
            const foundCategory = existingCategories.find(c => c.name === category.name);
            if (!foundCategory) continue; // Skip if category not found

            for (const subCategory of category.subCategories) {
                console.log(`Adding sub-category: ${subCategory.name}`);
                await addSubCategory({ name: subCategory.name, categoryId: foundCategory.id });
            }
        }
        console.log("Subcategories added successfully!");
    } catch (error) {
        console.error("Error while inserting subcategories:", error);
    }
};


export const fillModels = async (categories) => {
    try {
        const existingSubCategories = await getSubCategories(); // Fetch all inserted subcategories

        for (const category of categories) {
            for (const subCategory of category.subCategories) {
                const foundSubCategory = existingSubCategories.find(sub => sub.name === subCategory.name);
                if (!foundSubCategory) continue; // Skip if subcategory not found

                for (const model of subCategory.models) {
                    console.log(`Adding model: ${model.name}`);
                    await addModel({ name: model.name, image: model.image, subCategoryId: foundSubCategory.id });
                }
            }
        }
        console.log("Models added successfully!");
    } catch (error) {
        console.error("Error while inserting models:", error);
    }
};


export const fillProducts = async (data) => {
    try {
        const existingModels = await getModels(); // Fetch all inserted models

        for (const model of data.models) {
            const foundModel = existingModels.find(m => m.name === model.name);
            if (!foundModel) continue; // Skip if model not found

            for (const product of model.products) {
                console.log(`Adding product: ${product.name}`);
                await addProduct({
                    name: product.name,
                    length: product.length,
                    width: product.width,
                    thickness: product.thickness,
                    surface: product.surface,
                    image: product.image,
                    ambianceImage: product.ambianceImage,
                    technicalSpecification: product.technicalSpecification,
                    code: product.code,
                    description: product.description,
                    usages: product.usages,
                    modelId: foundModel.id
                });
            }
        }
        console.log("Products added successfully!");
    } catch (error) {
        console.error("Error while inserting products:", error);
    }
};


