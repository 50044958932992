import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Header from './components/Header/Header';
import BackOfficeHeader from './components/BackOffice/BackOfficeHeader/BackOfficeHeader';
import UnderConstruction from './components/UnderConstruction/UnderConstruction';
import Home from './components/Home/Home';
import Products from './components/Products/Products';
import ContactUs from './components/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';
import Decors from './components/Products/Product/Decors/Decors';
import DecorDetails from './components/Products/Product/Decors/Decor/DecorDetails/DecorDetails';
import WhatsappComponent from './components/WhatsappComponent/WhatsappComponent';
import AboutUs from './components/AboutUs/AboutUs';
import Gallery from './components/Gallery/Gallery';
import Login from './components/Login/Login';
import BackOfficeHome from './components/BackOffice/BackOfficeHome/BackOfficeHome';
import ProductForm from './components/BackOffice/ProductForm/ProductForm';
import CategoryForm from './components/BackOffice/CategoryForm/CategoryForm';
import SubCategoryForm from './components/BackOffice/SubCategoryForm/SubCategoryForm';
import ModelForm from './components/BackOffice/ModelForm/ModelForm';
import UserForm from './components/BackOffice/UserForm/UserForm';
import BackOfficeDecors from './components/BackOffice/BackOfficeDecros/BackOfficeDecors';
import FillDatabaseComponent from './components/Services/FillDatabase';
import ProtectedRoute from './components/ProtectedRoutes/ProtectedRoutes';

function App() {
  return (
    <Router>
      <AppHeader />
      <AppContent />
      <Footer />
    </Router>
  );
}

function AppHeader() {
  const location = useLocation();

  // Affiche la barre de navigation appropriée en fonction du contexte
  const isBackOffice = location.pathname.startsWith("/backOffice") || location.pathname.startsWith("/add") || location.pathname.startsWith("/update");

  return isBackOffice ? <BackOfficeHeader /> : <Header />;
}

function AppContent() {
  const location = useLocation();

  // Détermine si le composant WhatsApp doit être visible
  const isWhatsappVisible = !(location.pathname === "/admin" || location.pathname.startsWith("/backOffice") || location.pathname.startsWith("/add") );

  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:categoryName/:subCategoryName" element={<Products />} />
        <Route path="/decors/:categoryName/:subCategoryName/:id" element={<Decors />} />
        <Route path="/DecorDetails/:categoryName/:subCategoryName/:modelName/:id/:modelId" element={<DecorDetails />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/admin" element={<Login />} />
        

        <Route element={<ProtectedRoute />}>
          <Route path="/backOfficeHome" element={<BackOfficeHome />} />
          <Route path="/backOfficeDecors/:categoryName/:subCategoryName/:id" element={<BackOfficeDecors />} />
          <Route path="/addProduct" element={<ProductForm />} />
          <Route path="/addCategory" element={<CategoryForm />} />
          <Route path="/addSubCategory" element={<SubCategoryForm />} />
          <Route path="/addModel" element={<ModelForm />} />
          <Route path="/addUser" element={<UserForm />} />
          <Route path="/fillDatabase" element={<FillDatabaseComponent />} />
          <Route path="/updateProduct/:categoryName/:subName/:modelId/:productId" element={<ProductForm/>}  />
          <Route path="/updateModel/:categoryId/:subCategoryId/:modelId" element={<ModelForm/>}  />
          <Route path="/updateModel/:id" element={<ModelForm/>}  />
        </Route>

      </Routes>
      {isWhatsappVisible && <WhatsappComponent />}
    </>
  );
}

export default App;
