import GalleryImage from "./GalleryImage/GalleryImage";
import "./GalleryImages.css";

export default function GalleryImages(){

    const images = [
        './images/projects/gallery/1.jpg',
        './images/projects/gallery/2.jpg',
        './images/projects/gallery/3.jpg',
        './images/projects/gallery/4.jpg',
        './images/projects/gallery/5.jpg',
        './images/projects/gallery/6.jpg',
        './images/projects/gallery/7.jpg',
        './images/projects/gallery/8.jpg',
        './images/projects/gallery/9.jpg',
        './images/projects/gallery/10.jpg',
        './images/projects/gallery/11.jpg',
        './images/projects/gallery/12.jpg',
        './images/projects/gallery/13.jpg',
        './images/projects/gallery/14.jpg',
        './images/projects/gallery/15.jpg',
        './images/projects/gallery/16.jpg',
        './images/projects/gallery/17.jpg',
        './images/projects/gallery/18.jpg',
        './images/projects/gallery/19.jpg',
        './images/projects/gallery/20.jpg',
        './images/projects/gallery/21.jpg',
        './images/projects/gallery/22.jpg',
        './images/projects/gallery/23.jpg',
        './images/projects/gallery/24.jpg',
        './images/projects/gallery/25.jpg',
        './images/projects/gallery/26.jpg',
        './images/projects/gallery/27.jpg',
        './images/projects/gallery/28.jpg',
        './images/projects/gallery/29.jpg',
        './images/projects/gallery/30.jpg',
        './images/projects/gallery/31.jpg',
        './images/projects/gallery/32.jpg',
        './images/projects/gallery/33.jpg',
        './images/projects/gallery/34.jpg'
    ];

    return(
        <div className="gallery-images">
         {images.map((image) => (
            <GalleryImage image={image}/>
         ))}
        </div>
    );
}