import axios from 'axios';

const API_BASE_URL = 'https://decopa-back-office-production.up.railway.app/decopa'; 
//const API_BASE_URL = 'http://localhost/decopa'; 
const CATEGORY_URL = "/category";
const SUBCATEGORY_URL = "/subCategory";
const MODEL_URL = "/model";
const PRODUCT_URL = "/product"; 
const DELETE = "/delete";
const LOGIN_URL = "/authenticate";
const USER_URL = "/user"
const ADD = "/add";

export default API_BASE_URL

export const getCategories = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL + CATEGORY_URL}/all`);
        return response.data;
    } catch (error) {
        console.error("Error fetching categories:", error);
        throw error;
    }
};

export const getProductById = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL + PRODUCT_URL}/` + id);
        return response.data;
    } catch (error) {
        console.error("Error fetching the product:", error);
        throw error;
    }
};

export const getModels = async () => {
    const response = await axios.get(`${API_BASE_URL + MODEL_URL}/all`);
    return response.data;
};

export const getSubCategories = async () => {
    const response = await axios.get(`${API_BASE_URL + SUBCATEGORY_URL}/all`);
    return response.data;
};

export const getProducts = async () => {
    const response = await axios.get(`${API_BASE_URL + PRODUCT_URL}/all`);
    return response.data;
};


export const addProduct = async (productRequest) => {
    try {
        await axios.post(`${API_BASE_URL + PRODUCT_URL + ADD}`, productRequest);
        console.log("✅ Product added successfully.");
    } catch (error) {
        console.log("la category " + productRequest.categoryName + " le sub : " + productRequest.subCategoryId + "id model : " + productRequest.product.id)
        console.error("❌ Error adding product:", error.response?.data || error.message);
        throw error;
    }
};



export const addCategory = async (category) => {
    try {
        const response = await axios.post(`${API_BASE_URL + CATEGORY_URL}/add`, category);
        return response.data; // Return the created category with ID
    } catch (error) {
        console.error("Error adding category:", error);
        throw error;
    }
};

export const addSubCategory = async (subCategory) => {
    try {
        const response = await axios.post(`${API_BASE_URL + SUBCATEGORY_URL}/add`, subCategory);
        return response.data; // Return the created subcategory with ID
    } catch (error) {
        console.error("Error adding sub-category:", error);
        throw error;
    }
};

export const addModel = async (model) => {
    try {
        const response = await axios.post(`${API_BASE_URL + MODEL_URL}/add`, model);
        return response.data; // Return the created model with ID
    } catch (error) {
        
        console.log(" Category Name : " +model.categoryName + " subCategory id = " + model.subCategoryId +  "model id : " + model.model.id +" Model name : " + model.newModel.name) 
        console.error("Error adding model:", error);
        throw error;
    }
};

export const deleteModel = async (categoryName, subName,ModelId) => {
    try {
        const response = await axios.delete(`${API_BASE_URL + MODEL_URL + DELETE}/${categoryName}/${subName}/${ModelId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting Model:', error);
    }
};


export const deleteProduct = async (CategoryName,productId) => {
    try {
        const response = await axios.delete(`${API_BASE_URL + PRODUCT_URL + DELETE}/${CategoryName}/${productId}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting product:', error);
    }
};

// Update product
export const updateProduct = async (productId, updatedProductData) => {
    try {
        const response = await axios.put(`${PRODUCT_URL}/${productId}`, updatedProductData);
        alert('Product updated successfully!');
        return response.data;
    } catch (error) {
        console.error('Error updating product:', error);
        alert('Failed to update product. Please try again.');
    }
};

export const authenticateUser = async (userName, password) => {
    try {
        /*let response = false
        if(userName === "decopa" && password === "decopa"){
            response = true;
        }*/
        const response = await axios.post(`${API_BASE_URL}${USER_URL}${LOGIN_URL}`, {
            userName,
            password,
        });

        if (response != null ) {
            localStorage.setItem("isAuthenticated", "true");
            localStorage.setItem("username", userName);
            localStorage.setItem("isAdmin", response.data.admin)
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error("Login error:", error);
        return false;
    }
};

export const isAuthenticated = () => {
    return localStorage.getItem("isAuthenticated") === "true";
};

export const logout = () => {
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("username");
    localStorage.removeItem("isAdmin");
};

export const addUser = async (user) => {
    try {
        const response = await axios.post(`${API_BASE_URL + USER_URL}/add`, user);
        return response.data;
    } catch (error) {
        console.error("Error adding the user:", error);
        throw error;
    }
};

export const getUsers = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL + USER_URL}/all`);
        return response.data;
    } catch (error) {
        console.error("Error fetching all users:", error);
        throw error;
    }
};

export const deleteUser = async (userName) => {
    try {
        const response = await axios.delete(`${API_BASE_URL + USER_URL + DELETE}/${userName}`);
        return response.data;
    } catch (error) {
        console.error('Error deleting user:', error);
    }
};

