import './SideNavbar.css';
import { useEffect, useState, useRef } from 'react';

export default function SideNavbar({ categories, selectedCategory, onCategorySelect, onSubCategorySelect }) {
    const [expandedCategoryId, setExpandedCategoryId] = useState(null);
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [isVisible, setIsVisible] = useState(false); // Animation trigger
    const sidebarRef = useRef(null);

    // Toggle category and close sidebar when a category is selected
    const toggleCategory = (category) => {
        const newExpandedCategoryId = expandedCategoryId === category.id ? null : category.id;
        setExpandedCategoryId(newExpandedCategoryId);
        onCategorySelect(category);
        setIsSidebarVisible(false); // 👈 Fermer la sidebar après le clic sur une catégorie
    };

    // Close sidebar when clicking a sub-category
    const handleSubCategoryClick = (subCategory) => {
        onSubCategorySelect(subCategory);
        setIsSidebarVisible(false); // 👈 Fermer la sidebar après le clic sur une sous-catégorie
    };

    // Toggle Sidebar visibility
    const toggleSidebar = () => {
        setIsSidebarVisible((prevState) => !prevState); // Toggle entre visible et caché
    };

    useEffect(() => {
        // Animation à l'ouverture du menu
        setIsVisible(true);
    }, []);

    useEffect(() => {
        // Fermer la sidebar quand on clique en dehors
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                setIsSidebarVisible(false);
            }
        };

        if (isSidebarVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSidebarVisible]);

    return (
        <>
            <div
                ref={sidebarRef} // Reference to the sidebar container
                className={`side-navbar ${isSidebarVisible ? 'visible' : 'hidden'} ${isVisible ? 'slide-in' : ''}`}
            >
                {categories.map((category) => (
                    <div key={category.id}>
                        <div
                            onClick={() => toggleCategory(category)} // 👈 Ferme sidebar après sélection
                            className={selectedCategory?.id === category.id ? 'selected-category' : ''}
                        >
                            <img
                                src="../../icons/triangle.png"
                                alt="Toggle"
                                className={expandedCategoryId === category.id ? 'rotated' : ''}
                            />
                            <header>{category.name}</header>
                        </div>
                        {expandedCategoryId === category.id && (
                            <div className="subcategories expanded">
                                {category.subCategories.map((subCategory) => (
                                    <span
                                        key={subCategory.id}
                                        onClick={() => handleSubCategoryClick(subCategory)} // 👈 Ferme sidebar après sélection d'une sous-catégorie
                                    >
                                        - {subCategory.name}
                                    </span>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </div>

            {/* Toggle Button */}
            <div
                className={`arrow ${isSidebarVisible ? 'rotated-arrow' : ''}`}
                onClick={toggleSidebar} // Sidebar toggled by click
            >
                <img src="../../icons/arrow-1.png" alt="Toggle Sidebar" />
            </div>
        </>
    );
}
