import './ClientList.css';

export default function ClientsList({data}) {

    
    return (
        <div className='client-list'>
            {data.map((image) => (
                <img src={"../../../../" + image} />
                    
            ))}
        </div>
    );
}