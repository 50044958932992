import React, { useEffect, useState } from 'react';
import './SubCategoryForm.css';
import { getCategories, addSubCategory } from '../../Services/Api'; // Import API methods

export default function SubCategoryForm() {
    const [categories, setCategories] = useState([]); // List of categories
    const [subCategoryName, setSubCategoryName] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [isUpdateMode, setIsUpdateMode] = useState(false); // Toggle between Add/Update
    const [subCategories, setSubCategories] = useState([]); // Store subcategories of selected category
    const [selectedSubCategory, setSelectedSubCategory] = useState('');

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const data = await getCategories(); // Fetch categories
                setCategories(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, []);

    // Handle category selection
    const handleCategoryChange = (e) => {
        const categoryId = e.target.value;
        setSelectedCategory(categoryId);
        setSelectedSubCategory(''); // Reset subcategory selection

        const category = categories.find(cat => cat.id.toString() === categoryId);
        if (category) {
            setSubCategories(category.subCategories || []);
        }
    };

    // Handle subcategory selection when updating
    const handleSubCategoryChange = (e) => {
        const subCategoryId = e.target.value;
        setSelectedSubCategory(subCategoryId);

        // Find the sub-category and set its name in the input field
        const subCategory = subCategories.find(sub => sub.id.toString() === subCategoryId);
        if (subCategory) {
            setSubCategoryName(subCategory.name);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selectedCategory) {
            alert('Veuillez sélectionner une catégorie.');
            return;
        }

        const subCategoryRequest = {
            categoryName:"Category-" +selectedCategory,
            subCategory:{
                id: isUpdateMode ? selectedSubCategory : Math.floor(Math.random() * 9000) + 1000,
                name: subCategoryName,
                categoryId: selectedCategory,
                isUpdate:isUpdateMode
            }
            
        };

        try {
            await addSubCategory(subCategoryRequest); // Call API
            alert(`Sous-catégorie "${subCategoryName}" ${isUpdateMode ? 'mise à jour' : 'ajoutée'} avec succès !`);

            // Reset form
            setSubCategoryName('');
            setSelectedCategory('');
            setSelectedSubCategory('');
            setSubCategories([]);
            setIsUpdateMode(false);
        } catch (error) {
            console.error('Error adding/updating sub-category:', error);
            alert('Une erreur est survenue.');
        }
    };

    return (
        <div className='sub-category-form'>
            <h2>{isUpdateMode ? 'Modifier' : 'Ajouter'} une sous-catégorie</h2>
            <form onSubmit={handleSubmit}>
                <table>
                    <tbody>
                        <tr>
                            <td>
                            <div className="toggle-container">
                                <input 
                                    type="checkbox" 
                                    checked={isUpdateMode} 
                                    onChange={() => setIsUpdateMode(!isUpdateMode)} 
                                />
                                <span>{isUpdateMode ? 'Mise à jour' : 'Ajout'}</span>
                            </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="input-container">
                                    <select
                                        required
                                        value={selectedCategory}
                                        onChange={handleCategoryChange}
                                    >
                                        <option value="">Choisir une Catégorie</option>
                                        {categories.map((category) => (
                                            <option key={category.id} value={category.id}>
                                                {category.name}
                                            </option>
                                        ))}
                                    </select>
                                    <label>Catégorie</label>
                                </div>
                            </td>
                        </tr>

                        {isUpdateMode && (
                            <tr>
                                <td>
                                    <div className="input-container">
                                        <select
                                            required
                                            value={selectedSubCategory}
                                            onChange={handleSubCategoryChange}
                                            disabled={!subCategories.length}
                                        >
                                            <option value="">Sélectionner une sous-catégorie</option>
                                            {subCategories.map((sub) => (
                                                <option key={sub.id} value={sub.id}>
                                                    {sub.name}
                                                </option>
                                            ))}
                                        </select>
                                        <label>Sous-Catégorie</label>
                                    </div>
                                </td>
                            </tr>
                        )}

                        {/* Subcategory Name Input */}
                        <tr>
                            <td>
                                <div className="input-container">
                                    <input
                                        type="text"
                                        required
                                        value={subCategoryName}
                                        onChange={(e) => setSubCategoryName(e.target.value)}
                                    />
                                    <label>Nom de la sous-catégorie</label>
                                </div>
                            </td>
                        </tr>

                        {/* Submit Button */}
                        <tr>
                            <td>
                                {localStorage.getItem("isAdmin") == "true" ?(
                                    <button type="submit" className="submit-button">
                                        {isUpdateMode ? 'Mettre à jour' : 'Ajouter'}
                                    </button>
                                ):(
                                    <button type="submit" className="submit-button">
                                        soumettre a validation
                                    </button>
                                )}
                                    
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
}
