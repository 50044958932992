import "./UserCard.css";
import { deleteUser } from "../../../Services/Api"; // Import de la méthode API

export default function UserCard({ userId, userName, userEmail, userPhone }) {
    const handleDelete = async () => {
        const confirmDelete = window.confirm(`Voulez-vous supprimer l'utilisateur ${userName} ?`);
        if (confirmDelete) {
            try {
                await deleteUser(userName);
                alert(`Utilisateur ${userName} supprimé avec succès !`);
                window.location.reload();
            } catch (error) {
                console.error("Erreur lors de la suppression :", error);
                alert("Erreur lors de la suppression de l'utilisateur.");
            }
        }
    };

    return (
        <div className="user-card">
            <div>
                <img src="./images/avatar.png" alt="Avatar" />
            </div>
            <div>
                <span>{userName}</span>
                <span>{userEmail}</span>
                <span>{userPhone}</span>
            </div>
            <div>
                <span className="edit-button">Modifier</span>
                <span className="delete-button" onClick={handleDelete}>Supprimer</span>
            </div>
        </div>
    );
}
