import React, { useEffect, useState } from 'react';
import './CategoryForm.css';
import { getCategories, addCategory } from '../../Services/Api'; 

export default function CategoryForm() {
    const [categories, setCategories] = useState([]); 
    const [categoryName, setCategoryName] = useState('');
    const [isUpdateMode, setIsUpdateMode] = useState(false); 
    const [selectedCategory, setSelectedCategory] = useState('');

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const data = await getCategories(); 
                setCategories(data);
            } catch (error) {
                console.error('Erreur lors du chargement des catégories:', error);
            }
        };
        fetchCategories();
    }, []);

    // Gérer la sélection de la catégorie en mode update
    const handleCategoryChange = (e) => {
        const categoryId = e.target.value;
        setSelectedCategory(categoryId);

        // Trouver la catégorie et préremplir son nom
        const category = categories.find(cat => cat.id.toString() === categoryId);
        if (category) {
            setCategoryName(category.name);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const categoryRequest = {
            id: isUpdateMode ? selectedCategory : Math.floor(Math.random() * 9000) + 1000,
            name: categoryName
        };

        try {
            await addCategory(categoryRequest);
            alert(`Catégorie "${categoryName}" ${isUpdateMode ? 'mise à jour' : 'ajoutée'} avec succès !`);

            // Réinitialiser le formulaire
            setCategoryName('');
            setSelectedCategory('');
            setIsUpdateMode(false);
        } catch (error) {
            console.error('Erreur lors de l\'ajout/mise à jour de la catégorie:', error);
            alert('Une erreur est survenue.');
        }
    };

    return (
        <div className='category-form'>
            <h2>{isUpdateMode ? 'Modifier' : 'Ajouter'} une Catégorie</h2>
            <form onSubmit={handleSubmit}>
                <table>
                    <tbody>
                        <tr>
                            <td>
                            <div className="toggle-container">
                                <input 
                                    type="checkbox" 
                                    checked={isUpdateMode} 
                                    onChange={() => setIsUpdateMode(!isUpdateMode)} 
                                />
                                <span>{isUpdateMode ? 'Mise à jour' : 'Ajout'}</span>
                            </div>
                            </td>
                        </tr>
                        {/* Sélection de la catégorie en mode update */}
                        {isUpdateMode && (
                            <tr>
                                <td>
                                    <div className="input-container">
                                        <select
                                            required
                                            value={selectedCategory}
                                            onChange={handleCategoryChange}
                                        >
                                            <option value="">Sélectionner une catégorie</option>
                                            {categories.map((category) => (
                                                <option key={category.id} value={category.id}>
                                                    {category.name}
                                                </option>
                                            ))}
                                        </select>
                                        <label>Catégorie</label>
                                    </div>
                                </td>
                            </tr>
                        )}

                        {/* Input du nom de la catégorie */}
                        <tr>
                            <td>
                                <div className="input-container">
                                    <input
                                        type="text"
                                        required
                                        value={categoryName}
                                        onChange={(e) => setCategoryName(e.target.value)}
                                    />
                                    <label>Nom de la catégorie</label>
                                </div>
                            </td>
                        </tr>

                        {/* Bouton Submit */}
                        <tr>
                            <td>
                                {localStorage.getItem("isAdmin") == "true" ?(
                                    <button type="submit" className="submit-button">
                                        {isUpdateMode ? 'Mettre à jour' : 'Ajouter'}
                                    </button>
                                ):(
                                    <button type="submit" className="submit-button">
                                        soumettre a validation
                                    </button>
                                )}
                                
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    );
}
