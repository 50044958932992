import React, { useEffect, useState } from "react";
import UserCard from "../UserCard/UserCard";
import "./UserCards.css";
import { getUsers } from "../../../Services/Api"; // Import de la méthode API

export default function UserCards() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        async function fetchUsers() {
            try {
                const data = await getUsers(); // Récupérer les utilisateurs
                setUsers(data); // Mettre à jour l'état avec les utilisateurs
            } catch (error) {
                console.error("Erreur lors de la récupération des utilisateurs :", error);
            }
        }

        fetchUsers();
    }, []);

    return (
        <div className="user-cards">
            {users.length > 0 ? (
                users.map((user, index) => (
                    <UserCard
                        key={index}
                        userName={user.userName}
                        userPhone={user.phone}
                        userEmail={user.email}
                    />
                ))
            ) : (
                <p>Aucun utilisateur trouvé.</p>
            )}
        </div>
    );
}
