import React, { useState } from 'react';
import './UserForm.css';
import UserCards from './UserCards/UserCards';
import { addUser } from '../../Services/Api';

export default function UserForm() {
    const [userName, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setTelephone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        // Vérification des champs obligatoires
        if (!userName || !email || !phone || !password || !confirmPassword) {
            alert('Veuillez remplir tous les champs.');
            return;
        }

        // Vérification du format de l'email
        if (!/\S+@\S+\.\S+/.test(email)) {
            alert('Veuillez entrer un email valide.');
            return;
        }

        // Vérification du format du téléphone
        if (!/^\d{10}$/.test(phone)) {
            alert('Le numéro de téléphone doit contenir exactement 10 chiffres.');
            return;
        }

        // Vérification des mots de passe
        if (password !== confirmPassword) {
            alert('Les mots de passe ne correspondent pas.');
            return;
        }

        const newUser = {
            userName,
            email,
            phone,
            password,
        };

        addUser(newUser);
        console.log('✅ Nouvel utilisateur:', newUser);
        alert(`Utilisateur "${userName}" ajouté avec succès !`);
        window.location.reload();

        // Réinitialisation du formulaire
        setUsername('');
        setEmail('');
        setTelephone('');
        setPassword('');
        setConfirmPassword('');
    };

    return (
        <>
        {localStorage.getItem("isAdmin") == "true" ?(
            <>
            <UserCards />
            <div className='user-form'>
                <h2>Ajouter un Utilisateur</h2>
                <form onSubmit={handleSubmit}>
                    <table>
                        <tbody>
                            {/* Nom d'utilisateur */}
                            <tr>
                                <td colSpan="2">
                                    <div className="input-container">
                                        <input
                                            type="text"
                                            id="username"
                                            required
                                            value={userName}
                                            onChange={(e) => setUsername(e.target.value)}
                                        />
                                        <label htmlFor="username">Nom d'Utilisateur</label>
                                    </div>
                                </td>
                            </tr>

                            {/* Email */}
                            <tr>
                                <td colSpan="2">
                                    <div className="input-container">
                                        <input
                                            type="email"
                                            id="email"
                                            required
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        <label htmlFor="email">Email</label>
                                    </div>
                                </td>
                            </tr>

                            {/* Téléphone */}
                            <tr>
                                <td colSpan="2">
                                    <div className="input-container">
                                        <input
                                            type="tel"
                                            id="phone"
                                            required
                                            value={phone}
                                            onChange={(e) => setTelephone(e.target.value)}
                                        />
                                        <label htmlFor="phone">Téléphone</label>
                                    </div>
                                </td>
                            </tr>

                            {/* Mot de passe & Confirmation */}
                            <tr>
                                <td>
                                    <div className="input-container">
                                        <input
                                            type="password"
                                            id="password"
                                            required
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <label htmlFor="password">Mot de Passe</label>
                                    </div>
                                </td>
                                <td>
                                    <div className="input-container">
                                        <input
                                            type="password"
                                            id="confirmPassword"
                                            required
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                        />
                                        <label htmlFor="confirmPassword">Confirmer le Mot de Passe</label>
                                    </div>
                                </td>
                            </tr>

                            {/* Bouton d'envoi */}
                            <tr>
                                <td colSpan="2">
                                    <button type="submit" className="submit-button">
                                        Ajouter l'Utilisateur
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            </div>
            </>
            
        ):(
            <div className='non-admin'>
                <p>
                    Seul l'administrateur a le droit de modifier, supprimer et ajouter des utilisateurs
                </p>
            </div>
        )}
            
           
        </>
    );
}
