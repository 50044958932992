import { useState, useEffect } from 'react';
import BackOfficeSideNavbar from '../BackOfficeSideNavbar/BackOfficeSideNavbar';
import BackOfficeProducts from '../BackOfficeProducts/BackOfficeProducts';
import './BackOfficeHome.css';
import useCategories from '../../Services/DynamicCategories';
import { useLocation } from 'react-router-dom';

export default function BackOfficeHome() {
    const { categories, loading, error } = useCategories(); // Fetch categories dynamically
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);

    // Set first category & subcategory when data is available
    useEffect(() => {
        if (categories.length > 0) {
            setSelectedCategory(categories[0]);
            setSelectedSubCategory(categories[0].subCategories?.[0] || null);
        }
    }, [categories]);

    // Handle category selection
    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        setSelectedSubCategory(category.subCategories?.[0] || null);
    };

    // Handle subcategory selection
    const handleSubCategorySelect = (subCategory) => {
        setSelectedSubCategory(subCategory);
    };

    // Show loading and error messages
    if (loading) return <p>Loading categories...</p>;
    if (error) return <p>Error loading categories.</p>;
    if (!selectedCategory) return <p>No categories available.</p>;

    return (
        <div className="back-office-home">
            {/* Sidebar */}
            <BackOfficeSideNavbar
                categories={categories}
                selectedCategory={selectedCategory}
                onCategorySelect={handleCategorySelect}
                onSubCategorySelect={handleSubCategorySelect}
            />

            {/* Main Content */}
            <div className="back-office-content">
                {selectedCategory ? (
                    <BackOfficeProducts
                        modelsBySubCategory={selectedCategory.subCategories}
                        categoryName={selectedCategory.name}
                        subCategoryName={selectedSubCategory?.name || ""}
                    />
                ) : (
                    <p>Please select a category.</p>
                )}
            </div>
        </div>
    );
}
