import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ProductsList from './ProductsList/ProductsList';
import SideNavbar from './SideNavbar/SideNavbar';
import { getCategories } from '../Services/Api';
import './Products.css';

export default function Products() {
    const [categories, setCategories] = useState([]);
    const { categoryName, subCategoryName } = useParams();
    const navigate = useNavigate();

    const defaultModels = [
        { id: 991, name: 'PARQUET', image: './images/models/concept-neo/moderna/ambiance-moderna.png' },
        { id: 992, name: 'GAZON ARTIFICIEL', image: './images/models/castor/ambiance-castor.jpg' },
        { id: 993, name: 'PLACO PLATRE', image: './images/products/placo.jpg' },
        { id: 994, name: 'Gerflex (vinyle)', image: './images/products/gerflex.jpg' },
        { id: 995, name: 'tapis paillasson', image: './images/models/zemat/ambiance-zemat-blue.jpg' },
        { id: 996, name: 'Bois Extérieur', image: './images/home/home-1.png' },
        { id: 997, name: 'bardage - habillag', image: './images/products/bardage-2.png' },
        { id: 998, name: 'stores', image: './images/products/store.webp' },
    ];

    const [modelsBySubCategory, setModelsBySubCategory] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategoryName, setSelectedSubCategoryName] = useState('');
    const [isDefaultMode, setIsDefaultMode] = useState(true); // 👉 Par défaut, on affiche `defaultModels`

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const data = await getCategories();
                setCategories(data);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };
        fetchCategories();
    }, []);

    useEffect(() => {
        if (!categoryName) {
            setModelsBySubCategory([{ subCategoryName: 'Produits', models: defaultModels }]);
            setSelectedSubCategoryName('');
            setIsDefaultMode(true); // 👉 Mode par défaut activé
        } else {
            const category = categories.find(cat => cat.name.toLowerCase() === categoryName.toLowerCase());
            if (category) {
                if (subCategoryName === 'default') {
                    const groupedModels = category.subCategories.map(subCategory => ({
                        subCategoryName: subCategory.name,
                        models: subCategory.models,
                    }));
                    setModelsBySubCategory(groupedModels);
                } else {
                    const subCategory = category.subCategories.find(sub => sub.name.toLowerCase() === subCategoryName.toLowerCase());
                    if (subCategory) {
                        setModelsBySubCategory([{ subCategoryName: subCategory.name, models: subCategory.models }]);
                        setSelectedSubCategoryName(subCategory.name);
                    }
                }
                setSelectedCategory(category);
                setIsDefaultMode(false);
            } else {
                navigate('/products');
            }
        }
    }, [categories, categoryName, subCategoryName, navigate]);

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        const groupedModels = category.subCategories.map(subCategory => ({
            subCategoryName: subCategory.name,
            models: subCategory.models,
        }));
        setModelsBySubCategory(groupedModels);
        setSelectedSubCategoryName('');
        setIsDefaultMode(false);
    };

    const handleSubCategorySelect = (subCategory) => {
        setModelsBySubCategory([{ subCategoryName: subCategory.name, models: subCategory.models }]);
        setSelectedSubCategoryName(subCategory.name);
        setIsDefaultMode(false);
    };

    const handleDefaultModelClick = (modelName) => {
        const category = categories.find(cat => cat.name.toLowerCase() === modelName.toLowerCase());
        if (category) {
            navigate(`/products/${category.name}/default`);
        } else {
            alert(categories[0].name + " - " +categories[1].name + " - " +categories[2].name + " - " +categories[3].name + " - " +categories[4].name + " - " +categories[5].name + " - ")
            alert("Catégorie non trouvée !");
        }
    };

    return (
        <div className="products">
            <div>
                <p>Quand l'élégance s'exprime, elle choisit Decopa</p>
            </div>
            <div>
                <SideNavbar
                    categories={categories}
                    selectedCategory={selectedCategory}
                    onCategorySelect={handleCategorySelect}
                    onSubCategorySelect={handleSubCategorySelect}
                />
                <ProductsList
                    modelsBySubCategory={modelsBySubCategory}
                    categoryName={selectedCategory?.name || 'Produits'}
                    subCategoryName={selectedSubCategoryName}
                    isDefaultMode={isDefaultMode}
                    onDefaultModelClick={handleDefaultModelClick}
                />
            </div>
        </div>
    );
}
