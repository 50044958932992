import { Link, useNavigate } from 'react-router-dom';
import './BackOfficeSideNavbar.css';
import { logout } from '../../Services/Api';
import { useEffect, useState } from 'react';

export default function BackOfficeSideNavbar({ categories, selectedCategory, onCategorySelect, onSubCategorySelect }) {

    const navigate = useNavigate();
    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [isVisible, setIsVisible] = useState(false); // Animation trigger

    const handleLogout = () => {
        logout();
        navigate("/admin"); // Redirect to login page
    };

     // Toggle Sidebar visibility
        const toggleSidebar = () => {
            setIsSidebarVisible((prevState) => !prevState); // Toggle between visible and hidden
        };
    
        useEffect(() => {
            // Trigger slide-in animation on component mount
            setIsVisible(true);
    
            // Cleanup logic if needed
            return () => {};
        }, []);
    return (
        <>
        <div className={`back-office-side-navbar ${isSidebarVisible ? 'visible' : 'hidden'} ${isVisible ? 'slide-in' : ''}`}>
            <div>
                <img src="./images/avatar.webp" alt="Avatar" />
                <span>{localStorage.getItem("username")}</span>
            </div>
            <div>
                {categories.map((category) => (
                        <span onClick={() => {onCategorySelect(category); setIsSidebarVisible(false);}  } >{category.name}</span>
                ))}
                
                <button className="logout-button" onClick={handleLogout}>Déconnexion</button>

            </div>
        </div>
        <div
                className={`arrow ${isSidebarVisible ? 'rotated-arrow' : ''}`}
                onClick={toggleSidebar} // Sidebar toggled by click
            >
                <img src="../../icons/arrow-1.png" alt="Toggle Sidebar" />
            </div>
        </>
        
    );
}
