import React from "react";
import { fillCategories, fillSubCategories, fillModels, fillProducts } from "../FillDatabase/FilleDatabse";
import categories from "../../data/Categories";
import data from "../../data/Models";


export default function FillDatabaseComponent() {
    const handleFillDatabase = async () => {
        try {
            await fillCategories(categories);
            await fillSubCategories(categories);
            await fillModels(categories);
            await fillProducts(data);
            alert("Database filled successfully!");
        } catch (error) {
            alert("Error while filling the database. Check the console for details.");
        }
    };

    return (
        <div>
            <h2>Fill Database</h2>
            <h2>Fill Database</h2>
            <h2>Fill Database</h2>
            <button onClick={handleFillDatabase}>Start Data Import</button>
        </div>
    );
}