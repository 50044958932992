import './Gallery.css';
import GalleryImages from './GalleryImages/GalleryImages';

export default function Gallery(){
    return(
        <div className="galerie">
            <div>
                <div>
                    <p>
                        DÉCOUVREZ LE MEILLEUR DU REVETEMENT
                    </p>
                </div>
                <div>
                    <div></div>
                </div>
            </div>
            <GalleryImages/>
        </div>
    );
}