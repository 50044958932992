import { useState, useRef, useEffect } from "react";
import "./GalleryImage.css";

export default function GalleryImage({ image }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalRef = useRef(null);

    // Fonction pour afficher l'image en grand
    const openModal = () => {
        setIsModalOpen(true);
    };

    // Fonction pour fermer l'image en cliquant en dehors
    const closeModal = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            setIsModalOpen(false);
        }
    };

    useEffect(() => {
        if (isModalOpen) {
            document.addEventListener("mousedown", closeModal);
        } else {
            document.removeEventListener("mousedown", closeModal);
        }

        return () => {
            document.removeEventListener("mousedown", closeModal);
        };
    }, [isModalOpen]);

    return (
        <>
            {/* Image normale */}
            <div className="gallery-image" onClick={openModal}>
                <img src={image} alt="Gallery" />
            </div>

            {/* Modal d'affichage en grand */}
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content" ref={modalRef}>
                        <img src={image} alt="Full Size" />
                    </div>
                </div>
            )}
        </>
    );
}
