import './DecorDetails.css';
import { Link, useParams } from 'react-router-dom';
import Title from '../../../../../Title/Title';
import ProductTitle from '../../../../../ProductTitle/ProductTitle';
import { useEffect, useState } from 'react';
import useCategories from '../../../../../Services/DynamicCategories';
import API_BASE_URL from '../../../../../Services/Api';

export default function DecorDetails() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { id, categoryName, subCategoryName, modelId, modelName } = useParams();
    const { categories, loading, error } = useCategories(); // Fetch categories dynamically

    const [decor, setDecor] = useState(null);
    const [model, setModel] = useState(null);

    // Extract the model and product from fetched data
    useEffect(() => {
        if (categories.length > 0) {
            // Find the category
            const category = categories.find(cat => cat.name === categoryName);
            if (!category) return;

            // Find the subcategory
            const subCategory = category.subCategories.find(sub => sub.name === subCategoryName);
            if (!subCategory) return;

            // Find the model
            const foundModel = subCategory.models.find(m => m.id.toString() === modelId);
            setModel(foundModel);

            if (foundModel) {
                // Find the product (decor)
                const foundProduct = foundModel.products.find(p => p.id.toString() === id);
                setDecor(foundProduct);
            }
        }
    }, [categories, categoryName, subCategoryName, modelId, id]);

    if (loading) return <p>Loading decor details...</p>;
    if (error) return <p>Error loading decor details.</p>;
    if (!decor || !model) return <p>Decor or model not found.</p>;

    return (
        <div className='decor-details'>
            <ProductTitle
                categoryName={categoryName}
                subCategoryName={subCategoryName}
                productName={decor.name}
                modelName={modelName}
                path={'../../../../../'}
                id={modelId}
            />
            <div>
                <div>
                    <img src={`${API_BASE_URL + decor.ambianceImage}`} alt="Ambiance" />
                    <div>
                        {model.products.map((product) => (
                            <Link
                                key={product.id}
                                to={`/DecorDetails/${categoryName}/${subCategoryName}/${modelName}/${product.id}/${modelId}`}
                            >
                                <img src={`${API_BASE_URL + product.image}`} alt={product.name} />
                            </Link>
                        ))}
                    </div>
                </div>
                <div>
                    <header>{decor.name} - {decor.code}</header>
                    <p>{decor.description}</p>
                    <Title content={"Context d'utilisation"} />
                    <div>
                        {decor.usages ? decor.usages.split(' ').map((context, index) => (
                            <span key={index}>{context}</span>
                        )) : <span>Aucun contexte</span>}
                    </div>
                </div>

            </div>
            <div>
                <div>
                    <Title content={'Spécifications techniques'} />
                    <div>
                        <div><span>Longueur :</span><span>{decor.length} </span></div>
                        <div><span>Largeur:</span><span>{decor.width} </span></div>
                        <div><span>épaisseur :</span><span>{decor.thickness} </span></div>
                        <div><span>Surface :</span><span>{decor.surface}</span></div>
                    </div>
                </div>
                <img src={`${API_BASE_URL + decor.image}`}  alt={decor.name} />
            </div>
            <Title content={'Caractéristiques '} />
            <div>
                <a href={`${API_BASE_URL + model.technicalSpecificationImage}`}>
                    <img src={`${API_BASE_URL + model.technicalSpecificationImage}`} alt={`${model.technicalSpecificationImage}`} />
                </a>
            </div>
        </div>
    );
}
